import Vue from 'vue'
import App from './App.vue'
import vueCustomElement from 'vue-custom-element'
import 'document-register-element/build/document-register-element'
import vueChat from './components/VueChat'
Vue.use(vueCustomElement)
Vue.use(vueChat)
Vue.config.productionTip = false
Vue.customElement('vue-widget', App)
// new Vue({
//   render: h => h(App),
// }).$mount('#app')
