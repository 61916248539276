<template>
  <div id="app" :style="{background: backgroundColor}">
   <vueChat></vueChat>
   
   
      <div v-for="item in items" :key="item">
        <button  v-on:click="handlet(item), openChat()"> {{item}} </button>
      </div>
  </div>
</template>

<script>
import vueChat from './components/VueChat.vue'

export default {
  name: 'App',
  components: {
    vueChat
  },
  data() {
    return {
     
    }
  },
  computed: {
   
 
  watch: {
   
  },
  methods: {
  }
}
}
</script>

<style>
body {
  padding: 0px;
  margin: 0px;
}

</style>

