<template>
    <div id="app" :style="{background: backgroundColor}">
      <beautiful-chat
        :always-scroll-to-bottom="alwaysScrollToBottom"
        :close="closeChat"
        :colors="colors"
        :is-open="isChatOpen"
        :message-list= "messageList"
        :message-styling="messageStyling"
        :new-messages-count="newMessagesCount"
        :on-message-was-sent="onMessageWasSent"
        :open="openChat"
        :placeholder = "placeholder"
        :participants="participants"
        :show-close-button="true"
        :show-launcher="true"
        :show-emoji="false"
        :show-file="false"
        :show-typing-indicator="showTypingIndicator"
        :show-edition="false"
        :show-deletion="false"
        :disable-user-list-toggle="false"
        :title="chatWindowTitle"
        @onType="handleOnType"
        @edit="editMessage"
        @remove="removeMessage"
      >
      {{$title()}}
     
      </beautiful-chat>
      <div class="test_button">
        <div v-for="item in items" :key="item">
          <button  v-on:click="handlet(item), openChat()"> {{item}} </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import availableColors from './colors'
  import Vue from 'vue'
  import Chat from 'vue-beautiful-chat'
  Vue.use(Chat)
  import axios from 'axios'
  import VueAxios from 'vue-axios'
  Vue.use(VueAxios, axios)


  Vue.mixin({
  methods: {
    callTitle() {
      return `CodeChit`;
    },
  },
});
Vue.prototype.$title = () => 'CodeChit';
  export default {
    name: 'VueChat',
    components: {

    },
    props: ['text_test'],
    data() {
      return {
        items: ['1231231', '57657657', '5467576', '67567576', '6578687'],
        name: '',
        id_chat: '',
        from_text: this.text_test,
        form_chat: '',
        messageFromServer: '',
        msg: {},
        placeholder: "Введите сообщение...",
        messageList: this.form_chats(this.id_chat),
        participants: [{ id: 'support',  name: 'Техподдержка' }],
        newMessagesCount: 0,
        isChatOpen: false,
        showTypingIndicator: '',
        colors: null,
        availableColors,
        chosenColor: null,
        alwaysScrollToBottom: true,
        messageStyling: true,
        userIsTyping: false
      }
    },
    computed: {
      chatWindowTitle() {
        return '№ ' + this.id_chat
      },
      linkColor() {
        return this.chosenColor === 'dark' ? this.colors.sentMessage.text : this.colors.launcher.bg
      },
      backgroundColor() {
        return this.chosenColor === 'dark' ? this.colors.messageList.bg : '#fff'
      }
    },
    created() {
      this.setColor('blue');
      var chat = this;
      document.querySelector('.sopdu-chat-btn').addEventListener('click', function () {
        chat.handlet(this.getAttribute('data-id'));
        chat.openChat();
      });
    },
    mounted() {
      // this.messageList.forEach((x) => (x.liked = false))
      window.setInterval(() => {
        for (let i = 0; i < localStorage.length; i++) {
            var item = localStorage.key(i)
            if (item.startsWith('chat_')){
              console.log(localStorage.key(i));
              var ch_id = localStorage.key(i).substring(5);
              this.get_chat_message(ch_id, this.last_id(ch_id))
            }

        }
        // this.sendMessage(this.get_chat_message());
      }, 10000) //number of milliseconds
      
    },
   
    watch: {
      id_chat(newChatId) {
        localStorage.id_chat = newChatId
      }
    },
    methods: {

      async get_chat_message(id_chat, id_message) {
        await  axios({
        method: "POST",
        url: "https://mebel.ilnurit.ru/local/chat/getMessage.php",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          "Accept": "application/x-www-form-urlencoded"
        },
        data: {
          chat_id: id_chat,
          msg_id: id_message
        },
      })
      .then(res => {
        var messages;
        if ("chat_"+id_chat in localStorage) messages = JSON.parse(localStorage["chat_"+id_chat]);
        else messages = [];
        if (res.data && res.data.chat[`${"chat_" + id_chat}`]) {
          localStorage.setItem("chat_"+id_chat, JSON.stringify(messages.concat(res.data.chat[`${"chat_" + id_chat}`])));
          this.messageList = this.form_chats(this.id_chat);
          this.newMessagesCount = res.data.chat[`${"chat_" + id_chat}`].length;
        }
       })


      },
      
      form_chats(id_chat){
        if (localStorage["chat_" + id_chat] != null) {
          
        
          return JSON.parse(localStorage["chat_" + id_chat])
          
        }
       
      },
      last_id(id_chat){
        if (localStorage["chat_" + id_chat] != null) {
          var array_chat = JSON.parse(localStorage["chat_" + id_chat])
          console.log(array_chat[array_chat.length-1]["id"])
          return array_chat[array_chat.length-1]["id"]
          
        }
       
      },
      handlet: function (id_chat) {
        this.id_chat = id_chat
      },
      getNow() {
                      const today = new Date();
                      const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                      const dateTime = date +' '+ time;
                      return dateTime;
                  },
      sendMessage (text) {
        if (text.length > 0) {
          this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1
          this.onMessageWasSent({
            author: 'support',
            type: 'text',
            id: Math.random(),
            data: {
              text: text,
        }
          })
        }
      },
      handleTyping(text) {
        this.showTypingIndicator =
          text.length > 0 ? this.participants[this.participants.length - 1].id : ''
      },
      onMessageWasSent(message) {
       
        var chat_id = "chat_" + this.id_chat
        this.send_message_on_server(message, chat_id)
          // this.text_test.setItem("this.get_chat_message()")
          // alert(this.from_text)   
      },
      openChat() {
        this.messageList = this.form_chats(this.id_chat)
        this.isChatOpen = true
        this.newMessagesCount = 0
        
      },
    async send_message_on_server (message, chat_id) {
      const user_data = await axios.get('https://testsborka.vardek.online/local/chat/getUser.php')
      const today = new Date();  
      var chat_mes = {
      [`${chat_id}`] :  {
        "data": {"text": message.data.text},
        "user": user_data.data,
        "meta": today.toLocaleDateString() + " " + today.toLocaleTimeString()
     }
     }
      await  axios({
  method: "POST",
  url: "https://mebel.ilnurit.ru/local/chat/blender.php",
  headers: {
    "content-type": "application/x-www-form-urlencoded",
    "Accept": "application/x-www-form-urlencoded"
  },
  data: {
    chat: JSON.stringify(chat_mes),
  },
})
.then(res => {

  const today = new Date();
        this.msg = {
        author: message.author,
            type: 'text',
            id: res.data,
            data: {
              text:  message.data.text,
              meta:  today.toLocaleDateString() + " " + today.toLocaleTimeString()
        }}

  if (localStorage[chat_id] == null) {
          var mg = "[" + JSON.stringify(this.msg) + "]"
          localStorage.setItem(chat_id, mg);
          this.messageList = this.form_chats(this.id_chat)
        
        }
        else {
          var bar = []
          bar.push(localStorage[chat_id].split('[')[1])
          bar.push(JSON.stringify(this.msg))
          var split0 = localStorage[chat_id].split("[")[1]
          var split1 = split0.split(']')[0]
          var mg1 = "[" + split1 + "," + JSON.stringify(this.msg) + "]"
          localStorage.setItem(chat_id, mg1)
          this.messageList = this.form_chats(this.id_chat)
         
        }
       
})
.catch(err => {
  console.log("error in request", err);
});


    

      },
      closeChat() {
        this.isChatOpen = false
      },
      setColor(color) {
        this.colors = this.availableColors[color]
        this.chosenColor = color
      },
      showStylingInfo() {
        this.$modal.show('dialog', {
          title: 'Info',
          text:
            'You can use *word* to <strong>boldify</strong>, /word/ to <em>emphasize</em>, _word_ to <u>underline</u>, `code` to <code>write = code;</code>, ~this~ to <del>delete</del> and ^sup^ or ¡sub¡ to write <sup>sup</sup> and <sub>sub</sub>'
        })
      },
      messageStylingToggled(e) {
        this.messageStyling = e.target.checked
      },
      handleOnType() {
        this.$root.$emit('onType')
        this.userIsTyping = true
      },
      editMessage(message) {
        const m = this.messageList.find((m) => m.id === message.id)
        m.isEdited = true
        m.data.text = message.data.text
      },
      removeMessage(message) {
        if (confirm('Delete?')) {
          const m = this.messageList.find((m) => m.id === message.id)
          m.type = 'system'
          m.data.text = 'This message has been removed'
        }
      },
      like(id) {
        const m = this.messageList.findIndex((m) => m.id === id)
        var msg = this.messageList[m]
        msg.liked = !msg.liked
        this.$set(this.messageList, m, msg)
      }
    }
  }
  </script>
  
  <style>
  body {
    padding: 0px;
    margin: 0px;
  }
  .sc-chat-window {
    width: 370px;
    height: calc(100% - 120px);
    max-height: 590px;
    right: 25px;
    bottom: 100px;
    box-sizing: border-box;
    box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.1);
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    animation: fadeIn;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
  }
  
  .sc-header {
    font-size: x-large;
    flex-direction: row;
  }
  
  .sc-message--meta {
    text-align:left !important;
  }
  
  * {
    font-family: Avenir Next, Helvetica Neue, Helvetica, sans-serif;
  }
  
  .demo-description {
    max-width: 500px;
  }
  
  .demo-description img {
    max-width: 500px;
  }
  
  .demo-test-area {
    width: 300px;
    box-sizing: border-box;
  }

  .test_button {
    position: fixed;
    top: 0%;
    right: 0%;

  }
  
  .demo-test-area--text {
    box-sizing: border-box;
    width: 100%;
    margin: 0px;
    padding: 0px;
    resize: none;
    font-family: Avenir Next, Helvetica Neue, Helvetica, sans-serif;
    background: #fafbfc;
    color: #8da2b5;
    border: 1px solid #dde5ed;
    font-size: 16px;
    padding: 16px 15px 14px;
    margin: 0;
    border-radius: 6px;
    outline: none;
    height: 150px;
    margin-bottom: 10px;
  }
  
  .demo-monster-img {
    width: 400px;
    display: block;
    margin: 60px auto;
  }
  
  .text-center {
    text-align: center;
  }
  
  .colors a {
    color: #fff;
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 10px;
  }
  
  .toggle a {
    text-decoration: none;
  }
  
  .messageStyling {
    font-size: small;
  }
  </style>